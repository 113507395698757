import { defineComponent as _defineComponent } from 'vue'
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import MetadataModule from "@/store/modules/Metadata";
import DialogModule from "@/store/UI/Dialog";
import RenewableOptionsModule from "@ems/containers/RenewableOptions/RenewableOptions.module";
import DialogGuideRECsStandards from "@ems/containers/RenewableOptions/TheDialog/GuideRECsStandards.vue";
import {
  PREVIOUS_YEAR_RANGE_FILTER,
  CURRENT_YEAR,
  PAGE_SIZE_BUY_RECS,
} from "@ems/constants";
import { configure, Field, Form } from "vee-validate";
import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { object, string } from "yup";
import ScopeTwoEmissionModule from "../ScopeTwoEmission/ScopeTwoEmission.module";
import AccountModule from "@/store/modules/Account";
import { useRoute } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'TheListRECs.Filter',
  setup(__props, { expose: __expose }) {
  __expose();

const schema = object({
  Facility: string().required().label("Facility"),
  Standard: string().required().label("Standard"),
  Year: string().required().nullable().label("Year"),
});
const standardField = ref();
const facilityField = ref();
const facilities = computed(() => MetadataModule.dataFacilitiesGetter);
const energyType = computed(() => RenewableOptionsModule.energyTypeGetter);
const shoppingCart = computed(() => RenewableOptionsModule.shoppingCartGetter);
const route = useRoute();
const isLoadMetaData = computed(() => MetadataModule.isLoadMetaDataGetter);
const initialValues = {
  Facility: facilities.value[0] && facilities.value[0].Value, // situation another enter this page
  Type: "All",
  Year: CURRENT_YEAR,
  Standard: "",
};
const isLoadingBuyRec = ref(true);
const standard = reactive<any>([]);
const AccountRehashClient = computed(() => AccountModule.AccountClientRehash);
const collectData = reactive<any>({
  data: {
    Standard: "",
    Type: "",
    Year: "",
    FacilityId: "",
    AccountId: "",
    CurrentPage: 1,
  },
});
configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});
const options = ref();
const createYearOptions = () => {
  const minYear = CURRENT_YEAR - PREVIOUS_YEAR_RANGE_FILTER;
  const years = [];

  for (let i = CURRENT_YEAR; i >= minYear; i--) {
    years.push({
      Label: i,
      Value: i,
    });
  }
  options.value = years;
};
watch(
  // situation refresh this page
  () => facilities.value,
  () => {
    if (facilities.value.length > 0) {
      const facilityId = route?.query?.FacilityId
        ? route.query.FacilityId
        : facilities.value[0].Value;
      initialValues.Facility = facilityId as string;
      handleChangeFacility(facilityId);
    }
  }
);

const handleClickGuide = () => {
  DialogModule.showModal({ component: DialogGuideRECsStandards });
};
const addParamsToLocation = (params: any) => {
  history.pushState(
    {},
    "",
    `/#${route.path}?` +
      Object.keys(params)
        .map((key) => {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          );
        })
        .join("&")
  );
};
const isStandardsLoading = RenewableOptionsModule.isStandardsLoadingGetter;
const handleChangeFacility = async (
  value: any,
  isChangeStandardField?: boolean
) => {
  if (isChangeStandardField) {
    delete route.query.Standard;
    standardField.value = standardField.value.reset();
  }
  if (!value) standard.data = [];
  addParamsToLocation(route.query);
  const standardsTypeGetter = RenewableOptionsModule.standardsTypeGetter;
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;

  const found = facilitiesGetter.find((item) => item.Value === value);

  collectData.data = {
    ...collectData.data,
    FacilityId: value,
    Standard: route.query.Standard || "",
  };

  if (found) {
    const idx = standardsTypeGetter.findIndex((item) =>
      item.Allows.includes(found.CountryCode)
    );
    if (idx > -1) {
      standard.data = standardsTypeGetter;
    } else {
      const newArr = [...standardsTypeGetter];
      const idx_SS673 = standardsTypeGetter.findIndex(
        (item) => item?.Allows?.length > 0
      );
      idx_SS673 > -1 ? newArr.splice(idx_SS673, 1) : newArr;
      standard.data = newArr;
    }
  }
  // ensure request enough params
  if (!CURRENT_YEAR) return;
  await ScopeTwoEmissionModule.getAllDataAmountRECsChart({
    FacilityId: value,
    Year: collectData.data.Year || CURRENT_YEAR,
  });
};
const onChangeStandard = (value: any) => {
  collectData.data = {
    ...collectData.data,
    Standard: value,
  };
};
const onChangeType = (value: any) => {
  collectData.data = {
    ...collectData.data,
    Type: value,
  };
};
const onChangeYear = async (value: any) => {
  collectData.data = {
    ...collectData.data,
    Year: value,
  };
  if (!collectData.data.FacilityId) return;
  await ScopeTwoEmissionModule.getAllDataAmountRECsChart({
    FacilityId: collectData.data.FacilityId,
    Year: value,
  });
};
const parameterRenewableOptions = computed(
  () => RenewableOptionsModule.parameterRenewableOptionsGetter
);
const handleClickSearchRecs = async () => {
  delete collectData.data.AccountId;
  addParamsToLocation(collectData.data);
  collectData.data = {
    ...collectData.data,
    AccountId: AccountRehashClient.value.ExternalId,
    Limit: PAGE_SIZE_BUY_RECS,
    Type: collectData.data.Type === "All" ? "" : collectData.data.Type,
  };
  standardField.value = collectData.data.Standard;
  await RenewableOptionsModule.getBuyListAction(collectData.data);
};

onMounted(async () => {
  createYearOptions();
  await Promise.all([
    RenewableOptionsModule.getEnergyTypeAction(),
    RenewableOptionsModule.getStandardsAction(),
  ]).then((values: any) => {
    isLoadingBuyRec.value = false;
  });
  if (facilities.value[0]) {
    handleChangeFacility(facilities.value[0].Value, true);
  }

  if (options.value) {
    onChangeYear(options.value[0].Value);
  }
});
onUnmounted(() => {
  RenewableOptionsModule.emptyBuyList();
});

const __returned__ = { schema, standardField, facilityField, facilities, energyType, shoppingCart, route, isLoadMetaData, initialValues, isLoadingBuyRec, standard, AccountRehashClient, collectData, options, createYearOptions, handleClickGuide, addParamsToLocation, isStandardsLoading, handleChangeFacility, onChangeStandard, onChangeType, onChangeYear, parameterRenewableOptions, handleClickSearchRecs, BaseSvgIcon, get MetadataModule() { return MetadataModule }, get DialogModule() { return DialogModule }, get RenewableOptionsModule() { return RenewableOptionsModule }, DialogGuideRECsStandards, get PREVIOUS_YEAR_RANGE_FILTER() { return PREVIOUS_YEAR_RANGE_FILTER }, get CURRENT_YEAR() { return CURRENT_YEAR }, get PAGE_SIZE_BUY_RECS() { return PAGE_SIZE_BUY_RECS }, get configure() { return configure }, get Field() { return Field }, get Form() { return Form }, computed, onMounted, onUnmounted, reactive, ref, watch, get object() { return object }, get string() { return string }, get ScopeTwoEmissionModule() { return ScopeTwoEmissionModule }, get AccountModule() { return AccountModule }, get useRoute() { return useRoute } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})