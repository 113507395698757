import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "box pt-3 pb-4 pl-6 pr-8 relative overflow-hidden" }
const _hoisted_2 = { style: { height: '170px' } }
const _hoisted_3 = { class: "absolute rounded-r-full top-30 left-0 h-10 bg-black text-white text-2xl font-bold flex justify-center items-center" }
const _hoisted_4 = { class: "text-sm px-2" }
const _hoisted_5 = { class: "px-3 pt-1 pb-3 space-y-1" }
const _hoisted_6 = { class: "flex justify-between" }
const _hoisted_7 = { class: "leading-6 text-dark flex items-center flex-1 overflow-x-hidden relative" }
const _hoisted_8 = { class: "inline-block ml-6 mr-2 text-dark" }
const _hoisted_9 = { class: "2xl:text-lg leading-6" }
const _hoisted_10 = { class: "flex flex-wrap justify-between items-center mt-4" }
const _hoisted_11 = { class: "leading-6 text-dark relative" }
const _hoisted_12 = { class: "inline-block ml-6 text-dark" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_ellipsis = _resolveDirective("ellipsis")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["TheListRECsFilter"]),
    ($setup.renewAbleBuyList.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([
        'grid grid-cols-2 2xl:gap-4 gap-3 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3',
        $setup.isLoadingRenewableBuyList && 'isSubmitting-rounded',
        _ctx.styles.sellorder_height,
      ]),
          style: {"padding":"10px"}
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.renewAbleBuyList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(_ctx.styles.card),
              key: `${item.SellOrderId}`
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: "w-full h-full object-fill object-center bg-cover",
                  style: _normalizeStyle({
              backgroundImage: `${
                item?.EnergyValue &&
                !$setup.isEmpty($setup.getTechnologyType(item.EnergyValue))
                  ? `url('img/${$setup.getTechnologyType(item.EnergyValue)}.jpg')`
                  : `url('${$setup.DUMMY_CARD_IMAGE_URL}')`
              }`,
              backgroundBlendMode: 'multiply',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            })
                }, null, 4)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, " US$ " + _toDisplayString(($setup.groupPriceAvailableRecsIsNotExpired(item.SellOrderItemDetails) &&
                Math.min(
                  ...$setup.groupPriceAvailableRecsIsNotExpired(
                    item.SellOrderItemDetails
                  )
                )) ||
              0) + " - " + _toDisplayString(($setup.groupPriceAvailableRecsIsNotExpired(item.SellOrderItemDetails) &&
                Math.max(
                  ...$setup.groupPriceAvailableRecsIsNotExpired(
                    item.SellOrderItemDetails
                  )
                )) ||
              0) + "/MWh ", 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, [
                    _createVNode($setup["BaseSvgIcon"], {
                      class: "inline-block text-dark w-5 h-5 absolute left-0 right-0 top-1/2 transform -translate-y-1/2",
                      name: "location"
                    }),
                    _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_8, [
                      _createTextVNode(_toDisplayString($setup.dataCountriesContinents && $setup.dataCountriesContinents[item?.CountryCode as any]
                    ? $setup.dataCountriesContinents[item?.CountryCode as any]
                    : "N/A"), 1)
                    ])), [
                      [_directive_ellipsis]
                    ])
                  ]),
                  _createElementVNode("p", _hoisted_9, [
                    _createElementVNode("strong", null, _toDisplayString($setup.formatNumber(item.Quantity)) + " MWh ", 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("p", _hoisted_11, [
                    _createVNode($setup["BaseSvgIcon"], {
                      class: "inline-block text-dark w-5 h-5 absolute left-0 right-0 top-1/2 transform -translate-y-1/2",
                      name: "calendar"
                    }),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(item.VintageYear), 1)
                  ]),
                  _createElementVNode("button", {
                    class: "btn btn--green-primary flex justify-center items-center space-x-2 py-2",
                    onClick: _withModifiers(($event: any) => ($setup.handleClickCheckoutNow(item as any)), ["prevent"])
                  }, [
                    _createVNode($setup["BaseSvgIcon"], {
                      class: "inline-block text-white w-4 h-4",
                      name: "shopping_cart"
                    }),
                    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "inline-block leading-3" }, "Add", -1))
                  ], 8, _hoisted_13)
                ])
              ])
            ], 2))
          }), 128))
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([
        'flex justify-center items-center h-full',
        $setup.isLoadingRenewableBuyList && 'isSubmitting-rounded',
        _ctx.styles.sellorder_height,
      ])
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("p", null, "No Data", -1)
        ]), 2)),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_el_pagination, {
        class: "justify-end",
        layout: "prev, pager, next",
        "pager-count": $setup.RenewableOptionsModule.buyListPaginationGetter.TotalPage,
        "current-page": 
          $setup.RenewableOptionsModule.buyListPaginationGetter.CurrentPage
        ,
        "page-size": $setup.RenewableOptionsModule.buyListPaginationGetter.Limit,
        total: $setup.RenewableOptionsModule.buyListPaginationGetter.TotalRow,
        onCurrentChange: _cache[0] || (_cache[0] = (currentPage) => $setup.handleCurrentChange(currentPage))
      }, null, 8, ["pager-count", "current-page", "page-size", "total"])
    ])
  ]))
}