import { createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "space-y-3" }
const _hoisted_2 = {
  key: 0,
  class: "border-dashed border-gray-400 my-5"
}
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "flex justify-between" }
const _hoisted_5 = { class: "flex justify-end right-4" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'box p-4 relative h-48 overflow-hidden',
        $setup.isLoadingAmountRECsChartGetter && 'isSubmitting-rounded',
      ])
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold" }, " Amount of RECs needed to achieve your goal ", -1)),
      _createVNode($setup["GaugeChart"], { data: $setup.dataAmountChartGetter }, null, 8, ["data"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([
        'box p-4 relative',
        $setup.loadingGetCartItems && 'isSubmitting-rounded',
      ])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass($setup.isSubmitting && 'isSubmitting-rounded')
      }, [
        _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold" }, "Cart (Up to 5)", -1)),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.shoppingCart, (item, idx) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
              _createVNode($setup["TheItemOrder"], { item: item }, null, 8, ["item"]),
              (idx !== $setup.shoppingCart.length - 1)
                ? (_openBlock(), _createElementBlock("hr", _hoisted_2))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "border-gray-400 my-5" }, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-lg font-bold font-serif" }, "SUBTOTAL", -1)),
          _createElementVNode("span", null, [
            _createElementVNode("strong", null, "US$ " + _toDisplayString($setup.formatNumber(Number($setup.totalPrice), 2)), 1)
          ])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("hr", { class: "border-gray-400 my-5" }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-lg font-bold font-serif" }, "Total Price", -1)),
          _createElementVNode("span", null, [
            _createElementVNode("strong", null, "US$ " + _toDisplayString($setup.formatNumber(Number($setup.totalPrice), 2)), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "btn btn--green-primary px-7",
            disabled: $setup.isSubmitting || $setup.shoppingCart.length === 0,
            onClick: _withModifiers($setup.handleClickBuy, ["prevent"])
          }, " Buy ", 8, _hoisted_6)
        ])
      ], 2)
    ], 2)
  ]))
}