import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-3" }
const _hoisted_2 = {
  key: 0,
  class: "border-dashed border-gray-400 my-5"
}
const _hoisted_3 = { class: "text-gray-400" }
const _hoisted_4 = { class: "text-gray-400" }
const _hoisted_5 = { class: "text-cafe-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "custom-class": "2xl:w-2/5 xl:w-3/5 title-color",
    modelValue: $setup.DialogModule.isToggle,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.DialogModule.isToggle) = $event)),
    center: "",
    "before-close": $setup.handleClose,
    "show-close": false,
    "close-on-click-modal": false,
    title: "Do you wish to confirm this REC Purchase?"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", { class: "flex justify-end items-center space-x-4" }, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--white border-2 border-black",
          onClick: $setup.handleClose
        }, " No, go back "),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--green-primary",
          onClick: $setup.handleConfirm
        }, " Yes ")
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.shoppingCart, (item, idx) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
            _createVNode($setup["TheItemOrder"], {
              item: item,
              modePreview: true
            }, null, 8, ["item"]),
            (idx !== $setup.shoppingCart.length - 1)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_2))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "border-gray-400 my-5" }, null, -1)),
      _createElementVNode("p", _hoisted_3, " SUBTOTAL: US$ " + _toDisplayString($setup.formatNumber(Number($setup.totalPrice), 2)), 1),
      _createElementVNode("p", _hoisted_4, [
        _cache[1] || (_cache[1] = _createTextVNode(" Amount to be deducted for purchase: ")),
        _createElementVNode("strong", _hoisted_5, "US$ " + _toDisplayString($setup.formatNumber(Number($setup.totalPrice), 2)), 1)
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}