import { defineComponent as _defineComponent } from 'vue'
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import { BuyOderItemDTO } from "@/models/RenewableOptions";
import { computed, ref, toRefs, reactive } from "vue";
import RenewableOptionsModule from "../RenewableOptions.module";
import { formatNumber } from "@/utils/helpers/buildDataFormat";
import AccountModule from "@/store/modules/Account";
import { debounce } from "lodash";
interface Props {
  item: BuyOderItemDTO;
  modePreview?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TheItemOrder',
  props: {
    item: {},
    modePreview: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const AccountRehashClient = computed(() => AccountModule.AccountClientRehash);
const props = __props;
const { modePreview } = reactive(props);
const { item } = toRefs(props);
const amount = ref(item.value.Quantity);

const price = computed(
  () =>
    parseFloat(`${item.value.UnitPrice}`) * parseFloat(`${item.value.Quantity}`)
);
const handleChange = (value: number, item: any) => {
  RenewableOptionsModule.updateShoppingCart({
    item,
    value,
    accountId: AccountRehashClient.value.ExternalId,
  });
  const request = {
    accountId: AccountRehashClient.value.ExternalId,
    buyOrderItemId: item.BuyOrderItemId,
    quantity: value,
  };
  updateDataBuyOrderItem(request);
};
const updateDataBuyOrderItem = debounce(async (payload: any) => {
  RenewableOptionsModule.updateBuyOrderItemById(payload);
}, 1000);
const handleRemove = () => {
  RenewableOptionsModule.removeToShoppingCart({
    accountId: AccountRehashClient.value.ExternalId,
    buyOrderItemId: item?.value.BuyOrderItemId || "",
  });
};

const __returned__ = { AccountRehashClient, props, modePreview, item, amount, price, handleChange, updateDataBuyOrderItem, handleRemove, BaseSvgIcon, get BuyOderItemDTO() { return BuyOderItemDTO }, computed, ref, toRefs, reactive, get RenewableOptionsModule() { return RenewableOptionsModule }, get formatNumber() { return formatNumber }, get AccountModule() { return AccountModule }, get debounce() { return debounce } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})