import { defineComponent as _defineComponent } from 'vue'
import { BuyRecDTO, IBuyRECsForm } from "@/models/RenewableOptions";
import DialogModule from "@/store/UI/Dialog";
import { formatNumber } from "@/utils/helpers/buildDataFormat";
import DialogPurchaseRECsSuccessful from "@ems/containers/RenewableOptions/TheDialog/PurchaseRECsSuccessful.vue";
import { computed } from "vue";
import RenewableOptionsModule from "../RenewableOptions.module";
import TheItemOrder from "../TheSummary/TheItemOrder.vue";
import AccountModule from "@/store/modules/Account";
import WaitingForCheckout from "./WaitingForCheckout.vue";
import { useRoute, useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'PurchaseRECs',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const route = useRoute();
const parameterRenewableOptions = computed(
  () => RenewableOptionsModule.parameterRenewableOptionsGetter
);
const totalPrice = computed(() =>
  RenewableOptionsModule.shoppingCartGetter
    .reduce(
      (amount, field) =>
        amount +
        parseFloat(`${field.UnitPrice}`) * parseFloat(`${field.Quantity}`),
      0
    )
    .toFixed(2)
);

const shoppingCart = computed(() => RenewableOptionsModule.shoppingCartGetter);
const AccountRehashClient = computed(() => AccountModule.AccountClientRehash);

const handleClose = () => {
  const { toggleSubmitLoading } = DialogModule.getRest as any;

  toggleSubmitLoading();
  DialogModule.hideModal();
};

const handleConfirm = async () => {
  const { toggleSubmitLoading } = DialogModule.getRest as any;
  toggleSubmitLoading();

  DialogModule.hideModal();
  const payload = {
    accountId: AccountRehashClient.value.ExternalId,
    facilityId: parameterRenewableOptions.value.FacilityId,
    year: route?.query?.Year || 0,
  } as BuyRecDTO;
  // DialogModule.showModal({
  //   component: WaitingForCheckout,
  // });
  await RenewableOptionsModule.submitBuyRECs(payload);
  const params = {
    Standard: parameterRenewableOptions.value.Standard,
    Type: parameterRenewableOptions.value.Type,
    Year: parameterRenewableOptions.value.Year,
    FacilityId: parameterRenewableOptions.value.FacilityId,
    CurrentPage: 1,
    Limit: 6,
    AccountId: AccountRehashClient.value.ExternalId,
  } as any;
  RenewableOptionsModule.getBuyListAction(params);
};

const __returned__ = { router, route, parameterRenewableOptions, totalPrice, shoppingCart, AccountRehashClient, handleClose, handleConfirm, get BuyRecDTO() { return BuyRecDTO }, get IBuyRECsForm() { return IBuyRECsForm }, get DialogModule() { return DialogModule }, get formatNumber() { return formatNumber }, DialogPurchaseRECsSuccessful, computed, get RenewableOptionsModule() { return RenewableOptionsModule }, TheItemOrder, get AccountModule() { return AccountModule }, WaitingForCheckout, get useRoute() { return useRoute }, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})