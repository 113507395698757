import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { className: "text-3xl font-bold" }
const _hoisted_2 = { className: "flex flex-col gap-4 w-full mb-4" }
const _hoisted_3 = { className: "mt-4" }
const _hoisted_4 = { className: "bg-gray-05 rounded mt-4" }
const _hoisted_5 = { className: "p-4" }
const _hoisted_6 = { className: "" }
const _hoisted_7 = { className: " " }
const _hoisted_8 = { class: "pl-4 relative" }
const _hoisted_9 = { class: "mb-4 space-y-3 grid 2xl:gap-4 2xl:grid-cols-2 xl:gap-4 xl:grid-cols-1" }
const _hoisted_10 = ["onClick", "disabled"]
const _hoisted_11 = { className: "t-body-text" }
const _hoisted_12 = { className: "t-subtitle clr-gray-01" }
const _hoisted_13 = {
  class: /*@__PURE__*/_normalizeClass(['flex items-center'])
}
const _hoisted_14 = { class: "flex justify-end right-4" }
const _hoisted_15 = ["disabled"]

import {
  BuyOderRequestDTO,
  SellOrderItemDetails,
} from "@/models/RenewableOptions";
import DialogModule from "@/store/UI/Dialog";
import { computed, ref } from "vue";
import RenewableOptionsModule from "@ems/containers/RenewableOptions/RenewableOptions.module";
import AccountModule from "@/store/modules/Account";
import isEmpty from "lodash/isEmpty";
import MetadataModule from "@/store/modules/Metadata";


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckOut',
  setup(__props) {

const AccountRehashClient = computed(() => AccountModule.AccountClientRehash);
const initialValues = computed<any>(() => DialogModule.getData || {});
const amount = ref(1);
const itemClick = ref<SellOrderItemDetails>(
  initialValues.value.sellOrderItems?.SellOrderItemDetails?.length >= 1
    ? initialValues?.value?.sellOrderItems?.SellOrderItemDetails[0]
    : {}
);
const dataCountriesContinents = computed(
  () => MetadataModule.dataCountriesContinentsGetter
);
const handleClickPrice = (item: SellOrderItemDetails) => {
  itemClick.value = item;
};
const AddToCart = (whiteLabelName: string) => {
  const item = itemClick.value;
  const payload = {
    SellOrderItemDetailId: item?.SellOrderItemDetailId,
    Quantity: amount.value || 1,
    VintageYear: item?.VintageYear,
    UnitPrice: item?.UnitPrice,
    AccountId: AccountRehashClient.value.ExternalId,
    WhiteLabelName: whiteLabelName,
  } as BuyOderRequestDTO;
  RenewableOptionsModule.addToShoppingCart(payload);
  handleClose();
};
const handleClose = () => {
  DialogModule.hideModal();
};

return (_ctx: any,_cache: any) => {
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _unref(DialogModule).isToggle,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(DialogModule).isToggle) = $event)),
    width: "40%",
    center: "",
    "before-close": handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(initialValues.value?.sellOrderItems?.AssetName), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("tr", { className: "text-left mb-4" }, [
            _createElementVNode("th", { className: "p-4" }, "Country"),
            _createElementVNode("th", { className: "" }, "Fuel type"),
            _createElementVNode("th", { className: "" }, "Year")
          ], -1)),
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", _hoisted_4, [
              _createElementVNode("td", _hoisted_5, _toDisplayString(dataCountriesContinents.value[
                  initialValues.value?.sellOrderItems?.CountryCode
                ]
                  ? dataCountriesContinents.value[
                      initialValues.value?.sellOrderItems?.CountryCode
                    ]
                  : "N/A"), 1),
              _createElementVNode("td", _hoisted_6, _toDisplayString(initialValues.value?.sellOrderItems?.EnergyValue), 1),
              _createElementVNode("td", _hoisted_7, _toDisplayString(initialValues.value?.sellOrderItems?.VintageYear), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, [
            _cache[5] || (_cache[5] = _createElementVNode("p", { className: "text-xl font-bold" }, "Price:", -1)),
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(initialValues.value?.sellOrderItems?.SellOrderItemDetails?.filter(
                (x) => x.Quantity > 0
              ), (item, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: idx,
                  class: "flex items-center"
                }, [
                  _createElementVNode("button", {
                    type: "button",
                    size: "mini",
                    width: "110",
                    class: _normalizeClass([
                    'border rounded w-28 mr-4',
                    itemClick.value?.SellOrderItemDetailId ===
                      item.SellOrderItemDetailId &&
                      'bg-gray-900 text-cafe-black-100',
                  ]),
                    onClick: ($event: any) => (handleClickPrice(item)),
                    disabled: item.Quantity <= 0
                  }, [
                    _createElementVNode("span", _hoisted_11, " USD$ " + _toDisplayString(item.UnitPrice), 1)
                  ], 10, _hoisted_10),
                  _createElementVNode("p", _hoisted_12, _toDisplayString(item.Quantity) + " Units Available ", 1)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-xl font-bold mr-4" }, "Quantity", -1)),
              _createVNode(_component_el_input_number, {
                class: "custom_input_number text-black",
                modelValue: amount.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((amount).value = $event)),
                disabled: _unref(isEmpty)(itemClick.value),
                min: 1,
                max: itemClick.value?.Quantity,
                size: "small"
              }, null, 8, ["modelValue", "disabled", "max"])
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("hr", { class: "border-gray-400 my-5" }, null, -1)),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("button", {
                class: "btn btn--green-primary px-7",
                disabled: _unref(isEmpty)(itemClick.value),
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
                AddToCart(initialValues.value?.sellOrderItems.WhiteLabelName)
              ), ["prevent"]))
              }, " Add To Cart ", 8, _hoisted_15)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})