import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import DialogModule from "@/store/UI/Dialog";


export default /*@__PURE__*/_defineComponent({
  __name: 'WaitingForCheckout',
  setup(__props) {

const handleClose = () => {
  DialogModule.hideModal();
};

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _unref(DialogModule).isToggle,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(DialogModule).isToggle) = $event)),
    width: "40%",
    center: "",
    "before-close": handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("p", {
          class: _normalizeClass(['loading break-normal', _ctx.styles['loading-text']])
        }, " Thanks for you patience, loading time may exceed 5 seconds to secure the purchase. Thank you for your understanding. ", 2)
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})