import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { IRenewableOptionItemDetail } from "@/models/RenewableOptions";
import { onMounted, toRefs, watch } from "vue";
import { getContentMarker } from "./ContentMarker";
interface Props {
  data: any;
  dataCenterMap: any;
  classMap: any;
  contentMarker: any;
  mapTypeId: any;
  markerWhite: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    data: {},
    dataCenterMap: {},
    classMap: {},
    contentMarker: {},
    mapTypeId: {},
    markerWhite: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const { data, dataCenterMap, classMap, contentMarker, mapTypeId, markerWhite } =
  toRefs(props);

const initMap = () => {
  // if (process.env.NODE_ENV === "development") {
  //   return;
  // }
  // eslint-disable-next-line no-undef
  const googleMap = google;
  const dataMapping = data.value;
  const markerImage = {
    url: require("@/assets/images/marker-google-map-white.png"), // url
    scaledSize: new googleMap.maps.Size(30, 40),
  };
  const map = new googleMap.maps.Map(document.getElementById("mapMarker"), {
    zoom: 5,
    center: new googleMap.maps.LatLng(
      dataCenterMap.value.lat,
      dataCenterMap.value.lng
    ),
    mapTypeId: googleMap.maps.MapTypeId[mapTypeId.value],
  });
  const infoWindow = new googleMap.maps.InfoWindow({
    content: "",
    ariaLabel: "Uluru",
  });
  dataMapping?.map((item: IRenewableOptionItemDetail) => {
    const marker = new googleMap.maps.Marker({
      map: map,
      position: { lat: item.Latitude, lng: item.Longitude },
      icon: markerWhite.value ? markerImage : "",
    });

    googleMap.maps.event.addListener(
      marker,
      "mouseover",
      (function (marker, infoWindow) {
        return function (evt: any) {
          infoWindow.setContent(getContentMarker(contentMarker.value, item));
          infoWindow.open(map, marker);
        };
      })(marker, infoWindow)
    );
    googleMap.maps.event.addListener(
      marker,
      "mouseout",
      (function (marker, infoWindow) {
        return function (evt: any) {
          infoWindow.close(map, marker);
        };
      })(marker, infoWindow)
    );
  });
  googleMap.maps.event.addDomListener(window, "load", initMap);
};
onMounted(() => {
  initMap();
});
watch(data, () => {
  initMap();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "mapMarker",
    class: _normalizeClass(_unref(classMap))
  }, null, 2))
}
}

})