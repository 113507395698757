import { defineComponent as _defineComponent } from 'vue'
import DialogModule from "@/store/UI/Dialog";
import { formatNumber } from "@/utils/helpers/buildDataFormat";
import { computed, onMounted } from "vue";
import RenewableOptionsModule from "../RenewableOptions.module";


export default /*@__PURE__*/_defineComponent({
  __name: 'PurchaseRECsSuccessful',
  setup(__props, { expose: __expose }) {
  __expose();

const eWallet = computed(() => RenewableOptionsModule.eWalletGetter);

const handleClose = () => {
  DialogModule.hideModal();
};

onMounted(() => {
  RenewableOptionsModule.getEWallet();
});

const __returned__ = { eWallet, handleClose, get DialogModule() { return DialogModule }, get formatNumber() { return formatNumber }, computed, onMounted, get RenewableOptionsModule() { return RenewableOptionsModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})