import { defineComponent as _defineComponent } from 'vue'
import GaugeChart from "@/components/AnyChart/GaugeChart.vue";
import DialogModule from "@/store/UI/Dialog";
import { formatNumber } from "@/utils/helpers/buildDataFormat";
import DialogPurchaseRECs from "@ems/containers/RenewableOptions/TheDialog/PurchaseRECs.vue";
import ScopeTwoEmissionModule from "@ems/containers/ScopeTwoEmission/ScopeTwoEmission.module";
import { computed, onMounted, ref, watch } from "vue";
import RenewableOptionsModule from "../RenewableOptions.module";
import TheItemOrder from "./TheItemOrder.vue";
import AccountModule from "@/store/modules/Account";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();

const AccountRehashClient = computed(() => AccountModule.AccountClientRehash);
const isSubmitting = ref(false);
const shoppingCart = computed(() => RenewableOptionsModule.shoppingCartGetter);
const loadingGetCartItems = computed(
  () => RenewableOptionsModule.isLoadingGetCartItemsGetter
);
const totalPrice = computed(() =>
  RenewableOptionsModule?.shoppingCartGetter?.length > 0
    ? RenewableOptionsModule.shoppingCartGetter
        .reduce(
          (amount, field) =>
            amount +
            parseFloat(`${field.UnitPrice}`) * parseFloat(`${field.Quantity}`),
          0
        )
        .toFixed(2)
    : 0
);

const dataAmountChartGetter = computed(
  () => ScopeTwoEmissionModule.dataAmountChartGetter
);
const isLoadingAmountRECsChartGetter = computed(
  () => ScopeTwoEmissionModule.isLoadingAmountRECsChartGetter
);

const toggleSubmitLoading = (value = false) => {
  isSubmitting.value = value;
};
watch(
  () => AccountRehashClient.value,
  () => {
    RenewableOptionsModule.getCartItems(AccountRehashClient.value.Id);
  }
);
onMounted(() => {
  if (AccountRehashClient.value.Id) {
    RenewableOptionsModule.getCartItems(AccountRehashClient.value.Id);
  }
});
const handleClickBuy = () => {
  if (!isSubmitting.value && shoppingCart.value.length > 0) {
    toggleSubmitLoading(true);
    DialogModule.showModal({
      component: DialogPurchaseRECs,
      toggleSubmitLoading,
    });
  }
};

const __returned__ = { AccountRehashClient, isSubmitting, shoppingCart, loadingGetCartItems, totalPrice, dataAmountChartGetter, isLoadingAmountRECsChartGetter, toggleSubmitLoading, handleClickBuy, GaugeChart, get DialogModule() { return DialogModule }, get formatNumber() { return formatNumber }, DialogPurchaseRECs, get ScopeTwoEmissionModule() { return ScopeTwoEmissionModule }, computed, onMounted, ref, watch, get RenewableOptionsModule() { return RenewableOptionsModule }, TheItemOrder, get AccountModule() { return AccountModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})