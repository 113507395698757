import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.DialogModule.isToggle,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.DialogModule.isToggle) = $event)),
    center: "",
    "before-close": $setup.handleClose
  }, {
    title: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("h1", { class: "text-4xl font-bold" }, "What do these standards mean?", -1)
    ])),
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "mb-4 text-cafe-dark" }, " Renewable Energy Certificates (RECs) contain renewable energy attributes which are used to offset Scope 2 emissions. By purchasing RECs, you can meet your scope 2 carbon neutrality goals with varying compliance standards as follows: ", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "2xl:text-lg font-semibold" }, "Carbon Neutral Standards:", -1)),
      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "mb-4 text-cafe-dark" }, " Any CO2 emissions which are neutralised by the purchase of offsets, including the purchase of RECs which can be sourced globally. ", -1)),
      _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "2xl:text-lg font-semibold" }, "SS 673 Standards:", -1)),
      _cache[6] || (_cache[6] = _createElementVNode("p", { class: "mb-4 text-cafe-dark" }, " Renewable energy certificates are confined to geography limitations; user can consume energy in Singapore and purchase renewable energy certificates generated from Southeast Asian countries as defined by UN geoscheme classification system to neutralise their carbon footprint. User will be able to declare compliance with SS673:2021 standards. ", -1)),
      _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "2xl:text-lg font-semibold" }, "RE100 Standards:", -1)),
      _cache[8] || (_cache[8] = _createElementVNode("p", { class: "mb-4 text-cafe-dark" }, " Renewable energy certificates are confined to geography limitations; user must purchase renewable energy certificates generated from the same country (or within the market boundaries as defined by the RE100 standards) which energy is consumed in, to neutralise their carbon footprint. User will be able to declare compliance with RE100 standards. ", -1))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}