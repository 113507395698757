import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "grid grid-cols-12 gap-4 mb-5 relative" }
const _hoisted_5 = { class: "col-span-12" }
const _hoisted_6 = { class: "box" }
const _hoisted_7 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass([
        'grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-12 2xl:gap-5 gap-3 relative',
      ])
}
const _hoisted_8 = { class: "2xl:col-span-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/renewable-options" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Renewable Options")
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Buy RECs")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode($setup["GoogleMap"], {
            dataCenterMap: $setup.center,
            data: $setup.renewAbleBuyList,
            lineColor: '#8DC63E',
            classMap: _ctx.styles['map-size'],
            contentMarker: $setup.TypeContentMarker.CONTENT_ASSET_BUY_REC,
            mapTypeId: $setup.TypeMap.HYBRID,
            markerWhite: true
          }, null, 8, ["dataCenterMap", "data", "classMap", "contentMarker", "mapTypeId"])
        ])
      ])
    ]),
    (!$setup.isSuccess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode($setup["TheListRECs"])
          ]),
          _createVNode($setup["TheSummary"], { class: "2xl:col-span-5 space-y-4" })
        ]))
      : _createCommentVNode("", true)
  ]))
}