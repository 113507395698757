import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-16 pl-10 pr-14" }
const _hoisted_2 = { class: "flex border-2 border-black transition-all ease-out" }
const _hoisted_3 = {
  class: "absolute bottom-7 -right-10 w-20",
  style: {"white-space":"break-spaces"}
}
const _hoisted_4 = { class: "relative text-black" }
const _hoisted_5 = {
  class: "absolute bottom-7 -right-10 w-20",
  style: {"white-space":"break-spaces"}
}
const _hoisted_6 = { class: "relative text-black" }
const _hoisted_7 = { class: "relative text-black" }
const _hoisted_8 = { class: "w-16" }

import { formatNumber } from "@/utils/helpers/buildDataFormat";
import { onMounted, onUpdated, ref, toRefs } from "vue";

interface Props {
  data?: any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'GaugeChart',
  props: {
    data: {}
  },
  setup(__props: any) {

const props = __props;
const { data } = toRefs(props);

const widthCurrentBar = ref("0");
const widthTargetBar = ref("0");
const widthConsumedBar = ref("0");

const currentPower = ref();
const targetPower = ref();
const consumedPower = ref();

const currentText = ref();
const targetText = ref();
const consumedText = ref();

const isOverLap = ref(false);
const isOverTarget = ref(false);

const generateTextMarker = (chartData: any) => {
  let { Consumed, Target, Total } = chartData;
  if (
    typeof Consumed === "undefined" ||
    typeof Target === "undefined" ||
    typeof Total === "undefined"
  ) {
    isOverLap.value = true;
    targetText.value = "No data";
    consumedPower.value = "";
    currentPower.value = "";
    targetPower.value = "";
    return;
  }

  Consumed === 0 ? (Consumed = 1) : Consumed;
  const currentBarWidth = calculateCurrentWidth(Total, Consumed);
  const targetBarWidth = calculateTargetWidth(Target, currentBarWidth);

  if ((Total / Consumed) * 100 > Target) {
    widthCurrentBar.value = targetBarWidth;
    widthTargetBar.value = `${parseFloat(currentBarWidth) - targetBarWidth}`;
    currentText.value = `Target at ${Target}%`;
    targetText.value = `Current at ${((Total / Consumed) * 100).toFixed(0)}%`;
    consumedPower.value = `${formatNumber(
      Consumed - (Target * Consumed) / 100,
      2
    )} MWh`;
    currentPower.value = `${formatNumber((Target / 100) * Consumed, 2)} MWh`;
    targetPower.value = `${formatNumber(Total, 2)} MWh`;
    isOverTarget.value = true;
  } else {
    widthCurrentBar.value = currentBarWidth;
    widthTargetBar.value = targetBarWidth;
    currentText.value = `Current at ${((Total / Consumed) * 100).toFixed(0)}%`;
    targetText.value = `Target at ${Target}%`;
    consumedPower.value = `${formatNumber(
      Consumed - (Target * Consumed) / 100,
      2
    )} MWh`;

    currentPower.value = `${formatNumber(Total, 2)} MWh`;
    targetPower.value = `${formatNumber(
      (Target / 100) * Consumed - Total,
      2
    )} MWh`;
    isOverTarget.value = false;
  }
  consumedText.value = `Consumed to date ${formatNumber(Consumed, 2)} MWh`;
  widthConsumedBar.value = (
    100 -
    parseFloat(widthTargetBar.value) -
    parseFloat(widthCurrentBar.value)
  ).toFixed(2);

  isOverLap.value =
    parseFloat(widthTargetBar.value) <= 20 ||
    parseFloat(widthCurrentBar.value) == 100 ||
    parseFloat(widthTargetBar.value) == 0;
};

const calculateCurrentWidth = (Total: any, Consumed: any) => {
  return parseFloat(((Total / Consumed) * 100).toFixed(2)) > 100
    ? "100"
    : ((Total / Consumed) * 100).toFixed(2);
};
const calculateTargetWidth = (Target: any, currentWidth: any) => {
  return parseFloat((Target - parseFloat(currentWidth)).toFixed(2)) < 0
    ? Target
    : (Target - parseFloat(currentWidth)).toFixed(2);
};

onMounted(() => {
  generateTextMarker(data.value);
});
onUpdated(() => {
  generateTextMarker(data.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([
          'h-3 text-xs font-medium bg-green-400 text-blue-100 text-center leading-none relative transition-all ease-out duration-1000',
        ]),
        style: _normalizeStyle({
          width: widthCurrentBar.value + '%',
        })
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, [
            _createElementVNode("span", {
              class: _normalizeClass([isOverLap.value && _ctx.styles['text-overlap']])
            }, _toDisplayString(currentText.value), 3),
            _cache[0] || (_cache[0] = _createElementVNode("span", {
              class: "w-0 h-0 absolute -bottom-3 left-1/2 transform -translate-x-1/2",
              style: {"border-left":"4px solid transparent","border-right":"4px solid transparent","border-top":"8px solid #000"}
            }, null, -1))
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass([
            'absolute -bottom-5 left-1/2 transform -translate-x-1/2 whitespace-nowrap text-black',
            isOverLap.value && '-bottom-9',
          ])
        }, _toDisplayString(currentPower.value), 3)
      ], 4),
      _createElementVNode("div", {
        class: _normalizeClass([
          'h-3 bg-black text-xs font-medium text-blue-100 text-center leading-none relative transition-all ease-out duration-1000',
        ]),
        style: _normalizeStyle({ width: widthTargetBar.value + '%' })
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, [
            _createElementVNode("span", null, _toDisplayString(targetText.value), 1),
            _cache[1] || (_cache[1] = _createElementVNode("span", {
              class: "w-0 h-0 absolute -bottom-3 left-1/2 transform -translate-x-1/2",
              style: {"border-left":"4px solid transparent","border-right":"4px solid transparent","border-top":"8px solid #000"}
            }, null, -1))
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass([
            'absolute -bottom-5 left-1/2 transform -translate-x-1/2 whitespace-nowrap text-black',
            isOverTarget.value && isOverLap.value && '-bottom-9',
          ])
        }, _toDisplayString(targetPower.value), 3)
      ], 4),
      _createElementVNode("div", {
        class: "h-3 bg-transparent text-xs font-medium text-blue-100 text-center leading-none relative transition-all ease-out duration-1000",
        style: _normalizeStyle({
          width: widthConsumedBar.value + '%',
        })
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([
            'absolute  w-14',
            _unref(data).Target === 100 || _unref(data).Total / _unref(data).Consumed > 1
              ? '-bottom-5 -right-16'
              : 'bottom-7 -right-7',
          ])
        }, [
          _createElementVNode("p", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(consumedText.value), 1),
            _createElementVNode("span", {
              class: _normalizeClass([
                'w-0 h-0 absolute -bottom-3 left-1/2 transform -translate-x-1/2',
                _unref(data).Target === 100 || _unref(data).Total / _unref(data).Consumed > 1
                  ? 'hidden'
                  : '',
              ]),
              style: {"border-left":"4px solid transparent","border-right":"4px solid transparent","border-top":"8px solid #000"}
            }, null, 2)
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([
            'absolute -bottom-5 left-1/2 transform -translate-x-1/2 whitespace-nowrap text-black',
            _unref(data).Target === 100 || _unref(data).Total / _unref(data).Consumed > 1
              ? 'hidden'
              : '',
          ])
        }, _toDisplayString(consumedPower.value), 3)
      ], 4)
    ])
  ]))
}
}

})