import { defineComponent as _defineComponent } from 'vue'
import {
  IRenewableToBuyDTO,
  SellOrderItemDetails,
} from "@/models/RenewableOptions";
import { formatNumber } from "@/utils/helpers/buildDataFormat";
import { DUMMY_CARD_IMAGE_URL, PAGE_SIZE_BUY_RECS } from "@ems/constants";
import { computed } from "vue";
import RenewableOptionsModule from "./RenewableOptions.module";
import TheListRECsFilter from "./TheListRECs.Filter.vue";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import { useRoute, useRouter } from "vue-router";
import DialogModule from "@/store/UI/Dialog";
import DiaLogCheckout from "@ems/pages/ClientAdmin/RenewableOptions/Dialog/CheckOut.vue";
import AccountModule from "@/store/modules/Account";
import MetadataModule from "@/store/modules/Metadata";
import isEmpty from "lodash/isEmpty";

export default /*@__PURE__*/_defineComponent({
  __name: 'TheListRECs',
  setup(__props, { expose: __expose }) {
  __expose();

const renewAbleBuyList = computed(
  () => RenewableOptionsModule.renewableBuyListGetter
);
const AccountRehashClient = computed(() => AccountModule.AccountClientRehash);
const isLoadingRenewableBuyList = computed(
  () => RenewableOptionsModule.isLoadingRenewableBuyListGetter
);
const dataCountriesContinents = computed(
  () => MetadataModule.dataCountriesContinentsGetter
);

const groupPriceAvailableRecsIsNotExpired = (item: SellOrderItemDetails[]) => {
  return item
    ?.reduce(
      (acc: any, el: any) =>
        el.Quantity &&
        el.Quantity > 0 &&
        !el.IsExpired &&
        !acc.find((val: any) => val === el.UnitPrice)
          ? [...acc, el.UnitPrice as number]
          : acc,
      [] as number[]
    )
    .sort((a: any, b: any) => a - b);
};
const handleClickCheckoutNow = (item: IRenewableToBuyDTO) => {
  let tempItem = { ...item };
  const availableSellOrder = item.SellOrderItemDetails.filter(
    (item) => !item.IsExpired && parseInt(item.Quantity) > 0
  ).sort((a, b) => parseInt(`${a.UnitPrice}`) - parseInt(`${b.UnitPrice}`));
  tempItem.SellOrderItemDetails = [...availableSellOrder];
  DialogModule.showModal({
    component: DiaLogCheckout,
    data: {
      sellOrderItems: tempItem,
    },
  });
};
const getTechnologyType = (technology = "") => {
  if (technology.toLowerCase().includes("solar")) {
    return "solar";
  } else if (technology.toLowerCase().includes("wind")) {
    return "wind";
  } else if (
    technology.toLowerCase().includes("hydro") ||
    technology.toLowerCase().includes("hydro-small") ||
    technology.toLowerCase().includes("hydro-big")
  ) {
    return "hydro";
  } else if (technology.toLowerCase().includes("biogas")) {
    return "biogas";
  } else if (technology.toLowerCase().includes("biomass")) {
    return "biomass";
  } else if (technology.toLowerCase().includes("geothermal")) {
    return "geothermal";
  } else if (technology.toLowerCase().includes("nuclear")) {
    return "nuclear";
  }
  return "";
};
const route = useRoute();
const addParamsToLocation = (params: any) => {
  history.pushState(
    {},
    "",
    `/#${route.path}?` +
      Object.keys(params)
        .map((key) => {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          );
        })
        .join("&")
  );
};
const parameterRenewableOptions = computed(
  () => RenewableOptionsModule.parameterRenewableOptionsGetter
);
const handleCurrentChange = (currentPage: number) => {
  const params = {
    Standard: parameterRenewableOptions.value.Standard,
    Type: parameterRenewableOptions.value.Type,
    Year: parameterRenewableOptions.value.Year,
    FacilityId: parameterRenewableOptions.value.FacilityId,
    CurrentPage: currentPage,
    Limit: PAGE_SIZE_BUY_RECS,
    AccountId: AccountRehashClient.value.ExternalId,
  } as any;
  RenewableOptionsModule.getBuyListAction(params);
  delete params.AccountId;
  addParamsToLocation(params);
};

const __returned__ = { renewAbleBuyList, AccountRehashClient, isLoadingRenewableBuyList, dataCountriesContinents, groupPriceAvailableRecsIsNotExpired, handleClickCheckoutNow, getTechnologyType, route, addParamsToLocation, parameterRenewableOptions, handleCurrentChange, get IRenewableToBuyDTO() { return IRenewableToBuyDTO }, get SellOrderItemDetails() { return SellOrderItemDetails }, get formatNumber() { return formatNumber }, get DUMMY_CARD_IMAGE_URL() { return DUMMY_CARD_IMAGE_URL }, get PAGE_SIZE_BUY_RECS() { return PAGE_SIZE_BUY_RECS }, computed, get RenewableOptionsModule() { return RenewableOptionsModule }, TheListRECsFilter, BaseSvgIcon, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get DialogModule() { return DialogModule }, DiaLogCheckout, get AccountModule() { return AccountModule }, get MetadataModule() { return MetadataModule }, get isEmpty() { return isEmpty } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})