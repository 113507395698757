import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-gray-400" }
const _hoisted_2 = { class: "text-cafe-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.DialogModule.isToggle,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.DialogModule.isToggle) = $event)),
    center: "",
    width: "15%",
    "before-close": $setup.handleClose,
    "show-close": false
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", { class: "flex justify-end items-center space-x-4" }, [
        _createElementVNode("button", {
          type: "button",
          class: "text-green-400",
          onClick: $setup.handleClose
        }, " Ok ")
      ])
    ]),
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "font-black text-xl text-cafe-dark" }, "Purchase is successful.", -1)),
      _createElementVNode("p", _hoisted_1, [
        _cache[1] || (_cache[1] = _createTextVNode(" Balance in Credit: ")),
        _createElementVNode("strong", _hoisted_2, " $" + _toDisplayString($setup.formatNumber($setup.eWallet, 2)), 1)
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}