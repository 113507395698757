import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "w-full font-bold text-black" }
const _hoisted_2 = { class: "text-black text-xs w-full flex justify-between" }
const _hoisted_3 = { class: "text-black" }
const _hoisted_4 = {
  key: 1,
  class: "text-center w-full text-black"
}
const _hoisted_5 = { class: "2xl:text-sm xl:text-xs text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _directive_ellipsis = _resolveDirective("ellipsis")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'flex items-center text-sm ',
      !$setup.modePreview ? 'space-x-2' : 'space-x-1',
    ])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['w-48', _ctx.styles['item-order']])
    }, [
      _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_1, [
        _createTextVNode(_toDisplayString($setup.item.AssetName ? $setup.item.AssetName : "-"), 1)
      ])), [
        [_directive_ellipsis, { placement: 'bottom-right' }]
      ]),
      _createElementVNode("p", _hoisted_2, [
        _withDirectives((_openBlock(), _createElementBlock("span", null, [
          _createTextVNode(_toDisplayString($setup.item.CountryName ? $setup.item.CountryName : "-"), 1)
        ])), [
          [_directive_ellipsis, { placement: 'bottom-right' }]
        ]),
        _createElementVNode("span", _hoisted_3, _toDisplayString($setup.item.VintageYear ? $setup.item.VintageYear : "-"), 1)
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([!$setup.modePreview ? 'w-36' : 'w-28', _ctx.styles['item-order']])
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-black text-xs" }, "Quantity", -1)),
      (!$setup.modePreview)
        ? (_openBlock(), _createBlock(_component_el_input_number, {
            key: 0,
            class: "custom_input_number text-black",
            modelValue: $setup.amount,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.amount) = $event)),
            min: 1,
            max: $setup.item.SalesAvailable,
            size: "small",
            onChange: _cache[1] || (_cache[1] = (value) => $setup.handleChange(value, $setup.item))
          }, null, 8, ["modelValue", "max"]))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($setup.formatNumber($setup.amount)), 1))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([!$setup.modePreview ? 'w-36' : 'w-48', _ctx.styles['item-order']])
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-black text-xs" }, "Unit Price (per MWh)", -1)),
      _createElementVNode("p", _hoisted_5, " US$ " + _toDisplayString($setup.item?.UnitPrice?.toFixed(2)), 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([!$setup.modePreview ? 'w-36' : 'w-48', _ctx.styles['item-order']]),
      style: {"border-color":"black"}
    }, [
      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-black text-xs" }, "Price (US$)", -1)),
      _createElementVNode("p", {
        class: _normalizeClass(['font-bold text-black', _ctx.styles['text-price']])
      }, _toDisplayString($setup.formatNumber($setup.price, 2)), 3)
    ], 2),
    (!$setup.modePreview)
      ? (_openBlock(), _createBlock($setup["BaseSvgIcon"], {
          key: 0,
          class: _normalizeClass([['hover:opacity-70', _ctx.styles['button-delete']], "inline-block w-5 h-5 cursor-pointer"]),
          onClick: _withModifiers($setup.handleRemove, ["prevent"]),
          name: "remove"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true)
  ], 2))
}